/* ---------------------------------
PlayButton
--------------------------------- */

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import { IconButton } from 'components/atoms'
import { TbPlayerPlayFilled } from 'react-icons/tb'

export default function PlayButton({
  onClick,
  children,
  className,
  ...styleProps
}) {
  return (
    <span
      onClick={onClick}
      className={classNames(
        'PlayButton',
        className,
        extractStyleProps(styleProps)
      )}
    >
      <IconButton
        className={'PlayButton__icon'}
        ariaLabel={'Riproduci il video'}
      >
        <TbPlayerPlayFilled
          color={'#fff'}
          size={32}
        />
      </IconButton>
    </span>
  )
}

PlayButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  styleProps: PropTypes.object,
}
