const Spinner = ({ hasText = true }) => {
  return (
    <div className='loader-wrapper'>
      <span className='loader'></span>
      {hasText ? <span className='loader-text'></span> : null}
    </div>
  )
}

export default Spinner
