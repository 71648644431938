/* ---------------------------------
IconButton
--------------------------------- */

import { createElement, useMemo } from 'react'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import PropTypes from 'prop-types'

export default function IconButton({
  as = 'button',
  variant,
  className,
  onClick,
  href,
  rel,
  target,
  ariaLabel,
  children,
  ...styleProps
}) {
  const type = useMemo(() => getElement(as), [as])

  return createElement(
    type,
    {
      onClick,
      ...(as === 'a'
        ? {
            href: href ?? '/',
            rel: rel ?? 'noopener noreferrer',
            target: target ?? '_blank',
          }
        : {}),
      className: classNames(
        'IconButton',
        className,
        as === 'button' && 'IconButton--button',
        as === 'a' && 'IconButton--anchor',
        variant === 'bare' && 'IconButton--bare',
        extractStyleProps(styleProps)
      ),
      'aria-label': ariaLabel ?? '',
    },
    children
  )
}

function getElement(nodeType) {
  switch (nodeType) {
    case 'a':
      return 'a'

    case 'button':
    default:
      return 'button'
  }
}

IconButton.propTypes = {
  as: PropTypes.oneOf(['button', 'a']),
  variant: PropTypes.oneOf(['bare']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  styleProps: PropTypes.object,
}
