'use client'

/* ---------------------------------
Card
--------------------------------- */

import classNames from 'classnames'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

export default function Card({ children, className, as = 'article', variant }) {
  const Wrapper = motion[as]

  return (
    <Wrapper
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      className={classNames('Card', className)}
    >
      {variant === 'bare' ? (
        // No container, so no predetermined padding
        children
      ) : (
        <div className={classNames('Card__content')}>{children}</div>
      )}
    </Wrapper>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  as: PropTypes.oneOf(['article', 'div', 'section']),
  variant: PropTypes.oneOf(['bare']),
}
