/* ---------------------------------
Image
--------------------------------- */

import NextImage from 'next/image'
import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'

export default function Image({ styleProps, className, ...nextImageProps }) {
  return (
    <figure
      className={classNames('Image', className, extractStyleProps(styleProps))}
    >
      <NextImage {...nextImageProps} />
    </figure>
  )
}
