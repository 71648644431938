'use client'
import { createContext, useContext, useState } from 'react'

const metadata = {
  title: 'Dona | Fondazione AIRC per la ricerca sul cancro ETS',
  description: 'Pagina di donazione del sito AIRC dedicato alle donazioni',
}

const PayStoreContext = createContext()

export function PayStoreProvider({ children }) {
  const [store, setStore] = useState(null)

  return (
    <PayStoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </PayStoreContext.Provider>
  )
}

export function usePayStore() {
  return useContext(PayStoreContext)
}

export default function BaseLayout({ children }) {
  return (
    <>
      <main className={'content donation'}>
        <PayStoreProvider>{children}</PayStoreProvider>
      </main>
    </>
  )
}
