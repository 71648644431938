/* ---------------------------------
OpeningTitle
--------------------------------- */

import classNames from 'classnames'
import { extractStyleProps } from 'utils/styleProps'
import PropTypes from 'prop-types'

export default function OpeningTitle({
  children,
  negative,
  className,
  ...styleProps
}) {
  return (
    <span
      className={classNames(
        'OpeningTitle',
        className,
        negative && `OpeningTitle--negative`,
        extractStyleProps(styleProps)
      )}
    >
      {children}
    </span>
  )
}

OpeningTitle.propTypes = {
  children: PropTypes.node,
  negative: PropTypes.bool,
  className: PropTypes.string,
  styleProps: PropTypes.object,
}
